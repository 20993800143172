.logo {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 200;
    margin-left: 25px;
    z-index: 100;
}

.logo > span {
    opacity: 0;
    font-size: 24px;
}

.logo:hover {
    color: var(--brown);
    transition: 0.5s ease-in-out;
}

.logo:hover > span {    
    color: var(--brown);
    opacity: 1;
    transition: 0.5s ease-in-out;
}

@media (max-width: 450px) {
    .logo {
        font-size: 14px;
        margin-left: 10px;
    }
}