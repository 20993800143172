.contact__top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.contact__top {
    flex-direction: column;
}

.contact__top > h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 100px;
    font-weight: 200;
    letter-spacing: 5px;
}

.contact__bottom {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    height: 25vh;
    margin-bottom: 50px;
}

.contact__bottom-left, 
.contact__bottom-right {
    width: 40%;
}

.contact__bottom-left > p {
    width: 60%;
    letter-spacing: 2px;
    line-height: 1.5em;
}

@media (max-width: 450px) {
    .contact__bottom {
        flex-direction: column;
        width: 100%;
        height: 30vh;
    }

    .contact__bottom-left {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .contact__bottom-right {
        width: 100%;
    }

    .contact__bottom-left > p {
        width: 80%;
    }
}