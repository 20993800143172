@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

html {
  font-size: 62.5%;
  line-height: 1.25;
}

body {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: var(--black);
  transition: all 0.50s linear;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
span {
  font-size: 1.4rem;
}

a {
  font-weight: 600;
  color: var(--black);
  text-decoration: none;
}
