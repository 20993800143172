.book-body-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    padding-top: 100px;
}

.book-left-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    height: 80vh;
    overflow: hidden;
    text-align: left;
}

.book-left-container > h2 {
    font-size: 40px;
    letter-spacing: 5px;
    margin-bottom: 25px;
}

.book-left-container > p {
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 1.5em;
}

.book-left-container > a {
    font-size: 24px;
    margin-top: 25px;
}

.book-right-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 90vh;
    overflow: hidden;
}

.book-right-container > img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
}

@media (max-width: 450px) {
    .book-body-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
    }

    .book-left-container {
        order: 2;
        height: auto;
        width: 80%;
        margin-top: 65vh;
    }

    .book-right-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 75vh;
        margin-bottom: 50px;
    }

    .book-left-container > h2 {
        font-size: 24px;
    }
    
    .book-left-container > p {
        font-size: 14px;
    }
    
    .book-left-container > a {
        font-size: 20px;
    }
}