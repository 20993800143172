.store-images-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.store-imageOne {
    position: absolute;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    height: 60vh;
    width: 30%;
    top: 10%;
    left: 50%;
    order: 2;
    z-index: 2;
}

.store-imageTwo {
    position: absolute;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    height: 100vh;
    width: 40%;
    left: 15%;
    order: 1;
    z-index: 1;
}

.store-imageOne > img,
.store-imageTwo > img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
}

.store-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
    font-size: 50px;
    letter-spacing: 5px;
    text-transform: uppercase;
}

.store-products {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
}

@media (max-width: 450px) {
    .store-imageOne {
        width: 65%;
        left: 0;
        height: 50%;
    }

    .store-imageTwo {
        left: 20%;
        width: 80%;
    }

    .store-header {
       font-size: 24px;
    }

    .store-products {
        display: flex;
        flex-direction: column;
    }
}