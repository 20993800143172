:root {
    /* Colors */
    --brown: #8f796e;
    --yellow: #e8dcd2;
    --green: #c8e6c9;
    --blue: #a5c0d0;

    /* Neutrals */
    --white: #f2f2f2;
    --black: #363636;
    --grey: #c3c3c3;

    /* Shadows & Hovers */
    --shadow: #f6f6f6;
    --hover: #d6d6d6;
}

.App {
    font-family: 'Lato', sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--yellow);
    overflow-x: hidden;
}

.App::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
}
