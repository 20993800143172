.contactForm {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 35vh;
}

.contactForm label {
    display: none;
}

.contactForm-input input {
    display: flex;
    align-self: center;
    height: 48px;
    width: 90%;
    border-radius: 10px;
    padding: 10px;
    letter-spacing: 5px;
    border: 2px solid var(--mainGrey);
}

.contactForm-input textarea {
    display: flex;
    align-self: center;
    height: 100px;
    width: 90%;
    border-radius: 10px;
    padding: 10px;
    letter-spacing: 5px;
    border: 2px solid var(--mainGrey);
}

.contactForm-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 48px;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    text-transform: uppercase;
    letter-spacing: 5px;
    bottom: -10px;
    right: 60px;
    background-color: var(--brown);
    color: var(--white);
}

.contactForm-btn:hover {
    cursor: pointer;
    transition: 0.5s ease-in-out;
    background-color: var(--blue);
    color: var(--white);
}

@media (max-width: 450px) {
    .contactForm {
        width: 100%;
        height: 40vh;
        margin-left: 20px;
    }

    .contactForm-input input,
    .contactForm-input textarea {
        margin-bottom: 20px;
    }
}