.home-image-grid {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 175vh;
    width: 100%;
}

.imageOne {
    position: absolute;
    background-position: center;
    background-size: cover;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    top: 20vh;
    height: 35vh;
    width: 40%;
    z-index: 2;
    overflow: hidden;
    transition: 850ms;
}

.imageTwo {
    position: absolute;
    background-position: center;
    background-size: cover;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    top: 35vh;
    left: 50%;
    height: 70vh;
    width: 40%;
    z-index: 1;
    overflow: hidden;
    transition: 850ms;
}

.imageThree {
    position: absolute;
    background-position: center;
    background-size: cover;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    top: 100vh;
    left: 0;
    height: 60vh;
    width: 40%;
    z-index: 2;
    overflow: hidden;
    transition: 850ms;
}

.imageOne:before,
.imageTwo::before,
.imageThree::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);

}

.imageOne > img,
.imageTwo > img,
.imageThree > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    transition: 850ms;
}

.introText {
    position: absolute;
    display: flex;
    top: 60vh;
    left: 50px;
    width: 90%;
    text-transform: uppercase;
    letter-spacing: 10px;
    z-index: 3;
}

.introText > h1 {
    font-size: 75px;
    margin: 25px;
    color: var(--brown);
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

@media (max-width: 450px) {
    .imageOne {
        width: 60%;
    }

    .imageTwo {
        left: 40%;
        top: 25vh;
        width: 60%;
    }

    .imageThree {
        width: 75%;
    }

    .introText {
        display: flex;
        flex-direction: column;
        left: 0;
        text-transform: uppercase;
        letter-spacing: 5px;
    }

    .introText > h1 {
        margin: 0;
        width: 50%;
        font-size: 50px;
    }
}

.home-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 25vh;
    width: 50%;
    text-align: center;
    margin-bottom: 50px;
}

.home-description > h3 {
    font-size: 50px;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 10px;
}

.home-description > p {
    width: 75%;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 25px;
}

.featured-products {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    height: 50vh;
}

.featured-product-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px;
}

.featured-product-item-img {
    height: 40vh;
    width: 25vw;
    background-position: center;
    background-size: cover;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 25px;
    transition: 850ms;
}

.featured-product-item-img:hover {
    border-bottom-left-radius: 50%;
    border-top-right-radius: 50%;
    border-top-left-radius: 10%;
    border-bottom-right-radius: 10%;
}

.featured-product-item > h3 {
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 10px;
}

.featured-product-item-link > p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 150px;
    font-size: 16px;
    letter-spacing: 2px;
}

.featured-product-item-link > p > span {
    font-size: 36px;
    margin-right: 10px;
}

@media (max-width: 450px) {
    .home-description > h3 {
        font-size: 24px;
    }
    
    .featured-products {
        flex-direction: column;
        height: auto;
    }

    .featured-product-item-img {
        width: 80vw;
    }
}