@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab:wght@200&display=swap');
.navbar {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 25px;
    z-index: 100;
}

/* Nav Menu */
.navMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    background-color: var(--brown);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    transition: 850ms;
}

/* Nav Items */
.navbar__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    width: 100%;
    z-index: 100;
    transition: 850ms;
}

.navText {
    font-family: 'Montagu Slab', serif;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    list-style: none;
    text-decoration: none;
    color: var(--white);
    font-size: 75px;
    font-weight: 100;
    letter-spacing: 5px;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
}

.is-active {
    color: var(--grey);
}

.navText:hover {
    cursor: none;
    color: var(--black);
    transition: 0.5s ease;
}

@media (max-width: 450px) {
    .navText {
        font-size: 50px;
    }
}


