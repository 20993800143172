.about-intro-description {
    display: flex;
    justify-content: center;
    height: 20vh;
    width: 80%;
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 1.5em;
}

.about-intro-description > p {
    text-align: center;
}

.about-images-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.about-images-container > img:nth-child(1) {
    position: absolute;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    height: 40vh;
    width: 40vw;
    top: 10%;
    right: 50%;
    z-index: 2;
}

.about-images-container > img:nth-child(2) {
    position: absolute;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    height: 50vh;
    width: 30vw;
    right: 10%;
    top: 30%;
    z-index: 3;
}

.about-images-container > img:nth-child(3) {
    position: absolute;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    height: 90vh;
    width: 40vw;
    left: 30%;
    z-index: 1;
}

.about-description {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-top: 100px;
    margin-bottom: 50px;
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 1.5em;
}

@media (max-width: 450px) {
    .about-images-container > img:nth-child(1) {
       top: 70%;
       left: 0;
       width: 60%;
    }
    
    .about-images-container > img:nth-child(2) {
        display: none;
    }
    
    .about-images-container > img:nth-child(3) {
        top: 50%;
        height: 50vh;
        width: 80%;
    }
}
