@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montagu+Slab:wght@200&display=swap);
:root {
    /* Colors */
    --brown: #8f796e;
    --yellow: #e8dcd2;
    --green: #c8e6c9;
    --blue: #a5c0d0;

    /* Neutrals */
    --white: #f2f2f2;
    --black: #363636;
    --grey: #c3c3c3;

    /* Shadows & Hovers */
    --shadow: #f6f6f6;
    --hover: #d6d6d6;
}

.App {
    font-family: 'Lato', sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #e8dcd2;
    background-color: var(--yellow);
    overflow-x: hidden;
}

.App::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}
html {
  font-size: 62.5%;
  line-height: 1.25;
}

body {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: var(--black);
  transition: all 0.50s linear;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
span {
  font-size: 1.4rem;
}

a {
  font-weight: 600;
  color: var(--black);
  text-decoration: none;
}

.contactForm {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 35vh;
}

.contactForm label {
    display: none;
}

.contactForm-input input {
    display: flex;
    align-self: center;
    height: 48px;
    width: 90%;
    border-radius: 10px;
    padding: 10px;
    letter-spacing: 5px;
    border: 2px solid var(--mainGrey);
}

.contactForm-input textarea {
    display: flex;
    align-self: center;
    height: 100px;
    width: 90%;
    border-radius: 10px;
    padding: 10px;
    letter-spacing: 5px;
    border: 2px solid var(--mainGrey);
}

.contactForm-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 48px;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    text-transform: uppercase;
    letter-spacing: 5px;
    bottom: -10px;
    right: 60px;
    background-color: var(--brown);
    color: var(--white);
}

.contactForm-btn:hover {
    cursor: pointer;
    transition: 0.5s ease-in-out;
    background-color: var(--blue);
    color: var(--white);
}

@media (max-width: 450px) {
    .contactForm {
        width: 100%;
        height: 40vh;
        margin-left: 20px;
    }

    .contactForm-input input,
    .contactForm-input textarea {
        margin-bottom: 20px;
    }
}
footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 10vh;
    margin-bottom: 10px;
}

.footer__social {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 5vh;
    width: 25%;
}

.footer__social > a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    color: var(--white);
    background-color: var(--black);
}

.footer__social > a:hover {
    background-color: var(--brown);
    color: var(--grey);
    transition: 0.5s ease-in-out;
}

.footer__credits {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 10px;
    margin-top: 20px;
    width: 40%;
}

.footer__credits > p {
    font-size: 10px;
}

.footer__credits > a {
    font-weight: normal;
}

.footer__credits > p:hover, a:hover {
    color: var(--grey);
    transition: 0.5s ease-in-out;
}

@media (max-width: 480px) {
    footer {
        margin-bottom: 5px;
    }

    .footer__social {
        width: 50%;
    }

    .footer__credits {
        width: 100%;
    }    
}

.intro-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 100%;
    margin-top: 10vh;
}

.intro-container > h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 100px;
    font-weight: 200;
    letter-spacing: 5px;
    width: 75%;
}

@media (max-width: 450px) {
    .intro-container > h2 {
        font-size: 40px;
    }
}
.logo {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 200;
    margin-left: 25px;
    z-index: 100;
}

.logo > span {
    opacity: 0;
    font-size: 24px;
}

.logo:hover {
    color: var(--brown);
    transition: 0.5s ease-in-out;
}

.logo:hover > span {    
    color: var(--brown);
    opacity: 1;
    transition: 0.5s ease-in-out;
}

@media (max-width: 450px) {
    .logo {
        font-size: 14px;
        margin-left: 10px;
    }
}
.menuBtn {
    background-color: var(--black);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    margin-right: 50px;
    border-radius: 50%;
    z-index: 500;
}

.menuBtn .menuBtn__txt {
    position: absolute;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 12px;
    right: 100%;
    margin-right: 2px;
    z-index: 100;
    opacity: 0;
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
    transition: 0.3s 0s;
}

.menuBtn:hover > .menuBtn__txt {
    opacity: 1;
    transition: 0.5s ease-in-out;
}

@media (max-width: 450px) {
    .menuBtn {
        margin-right: 10px;
    }
}
.navbar {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 25px;
    z-index: 100;
}

/* Nav Menu */
.navMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    background-color: var(--brown);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    transition: 850ms;
}

/* Nav Items */
.navbar__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    width: 100%;
    z-index: 100;
    transition: 850ms;
}

.navText {
    font-family: 'Montagu Slab', serif;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    list-style: none;
    text-decoration: none;
    color: var(--white);
    font-size: 75px;
    font-weight: 100;
    letter-spacing: 5px;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
}

.is-active {
    color: var(--grey);
}

.navText:hover {
    cursor: none;
    color: var(--black);
    transition: 0.5s ease;
}

@media (max-width: 450px) {
    .navText {
        font-size: 50px;
    }
}



.about-intro-description {
    display: flex;
    justify-content: center;
    height: 20vh;
    width: 80%;
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 1.5em;
}

.about-intro-description > p {
    text-align: center;
}

.about-images-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.about-images-container > img:nth-child(1) {
    position: absolute;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    height: 40vh;
    width: 40vw;
    top: 10%;
    right: 50%;
    z-index: 2;
}

.about-images-container > img:nth-child(2) {
    position: absolute;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    height: 50vh;
    width: 30vw;
    right: 10%;
    top: 30%;
    z-index: 3;
}

.about-images-container > img:nth-child(3) {
    position: absolute;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    height: 90vh;
    width: 40vw;
    left: 30%;
    z-index: 1;
}

.about-description {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-top: 100px;
    margin-bottom: 50px;
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 1.5em;
}

@media (max-width: 450px) {
    .about-images-container > img:nth-child(1) {
       top: 70%;
       left: 0;
       width: 60%;
    }
    
    .about-images-container > img:nth-child(2) {
        display: none;
    }
    
    .about-images-container > img:nth-child(3) {
        top: 50%;
        height: 50vh;
        width: 80%;
    }
}

.blog-items-container {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    align-items: center;
    width: 1none;
    height: auto;
}

.blog-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 25vw;
    height: 50vh;
    margin: 25px;
}

.blog-item-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25vw;
    height: 30vh;
    transition: 850ms;
    overflow: hidden;
}

.blog-item-img > img {
    object-fit: cover;
    object-position: center;
    transition: 850ms;
}

.blog-item:hover .blog-item-img {
    border-bottom-left-radius: none;
    border-top-right-radius: 50%;
    border-top-left-radius: 10%;
    border-bottom-right-radius: 10%;
}

.blog-item:hover .blog-item-img > img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: 0.5s ease-in-out;
}

.blog-item > h2 {
    font-size: 30px;
    margin: 10px;
}

.blog-item-description {
    letter-spacing: 2px;
    line-height: 1.5em;
}

@media (max-width: 450px) {
    .blog-items-container {
        display: flex;
        flex-direction: column;
    }

    .blog-item {
        width: 80vw;
        height: 50vh;
        margin: 25px;
    }

    .blog-item-img {
        width: 80vw;
    }

    .blog-item:hover .blog-item-img {
        border-bottom-left-radius: none;
        border-top-right-radius: none;
        border-top-left-radius: none;
        border-bottom-right-radius: none;
    }
}
.blog-post-main-img-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 75vh;
}

.blog-post-title {
    position: absolute;
    left: 50px;
    bottom: 25px;
    font-size: 30px;
    letter-spacing: 2px;
    color: var(--grey);
}

.blog-post-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin: 50px auto;
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 1.5em;
}

@media (max-width: 450px) {
    .blog-post-title {
        left: 25px;
    }
}


.book-body-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    padding-top: 100px;
}

.book-left-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    height: 80vh;
    overflow: hidden;
    text-align: left;
}

.book-left-container > h2 {
    font-size: 40px;
    letter-spacing: 5px;
    margin-bottom: 25px;
}

.book-left-container > p {
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 1.5em;
}

.book-left-container > a {
    font-size: 24px;
    margin-top: 25px;
}

.book-right-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 90vh;
    overflow: hidden;
}

.book-right-container > img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
}

@media (max-width: 450px) {
    .book-body-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
    }

    .book-left-container {
        order: 2;
        height: auto;
        width: 80%;
        margin-top: 65vh;
    }

    .book-right-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 75vh;
        margin-bottom: 50px;
    }

    .book-left-container > h2 {
        font-size: 24px;
    }
    
    .book-left-container > p {
        font-size: 14px;
    }
    
    .book-left-container > a {
        font-size: 20px;
    }
}
.contact__top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.contact__top {
    flex-direction: column;
}

.contact__top > h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 100px;
    font-weight: 200;
    letter-spacing: 5px;
}

.contact__bottom {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    height: 25vh;
    margin-bottom: 50px;
}

.contact__bottom-left, 
.contact__bottom-right {
    width: 40%;
}

.contact__bottom-left > p {
    width: 60%;
    letter-spacing: 2px;
    line-height: 1.5em;
}

@media (max-width: 450px) {
    .contact__bottom {
        flex-direction: column;
        width: 100%;
        height: 30vh;
    }

    .contact__bottom-left {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .contact__bottom-right {
        width: 100%;
    }

    .contact__bottom-left > p {
        width: 80%;
    }
}
.home-image-grid {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 175vh;
    width: 100%;
}

.imageOne {
    position: absolute;
    background-position: center;
    background-size: cover;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    top: 20vh;
    height: 35vh;
    width: 40%;
    z-index: 2;
    overflow: hidden;
    transition: 850ms;
}

.imageTwo {
    position: absolute;
    background-position: center;
    background-size: cover;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    top: 35vh;
    left: 50%;
    height: 70vh;
    width: 40%;
    z-index: 1;
    overflow: hidden;
    transition: 850ms;
}

.imageThree {
    position: absolute;
    background-position: center;
    background-size: cover;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    top: 100vh;
    left: 0;
    height: 60vh;
    width: 40%;
    z-index: 2;
    overflow: hidden;
    transition: 850ms;
}

.imageOne:before,
.imageTwo::before,
.imageThree::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);

}

.imageOne > img,
.imageTwo > img,
.imageThree > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    transition: 850ms;
}

.introText {
    position: absolute;
    display: flex;
    top: 60vh;
    left: 50px;
    width: 90%;
    text-transform: uppercase;
    letter-spacing: 10px;
    z-index: 3;
}

.introText > h1 {
    font-size: 75px;
    margin: 25px;
    color: var(--brown);
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

@media (max-width: 450px) {
    .imageOne {
        width: 60%;
    }

    .imageTwo {
        left: 40%;
        top: 25vh;
        width: 60%;
    }

    .imageThree {
        width: 75%;
    }

    .introText {
        display: flex;
        flex-direction: column;
        left: 0;
        text-transform: uppercase;
        letter-spacing: 5px;
    }

    .introText > h1 {
        margin: 0;
        width: 50%;
        font-size: 50px;
    }
}

.home-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 25vh;
    width: 50%;
    text-align: center;
    margin-bottom: 50px;
}

.home-description > h3 {
    font-size: 50px;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 10px;
}

.home-description > p {
    width: 75%;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 25px;
}

.featured-products {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    height: 50vh;
}

.featured-product-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px;
}

.featured-product-item-img {
    height: 40vh;
    width: 25vw;
    background-position: center;
    background-size: cover;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 25px;
    transition: 850ms;
}

.featured-product-item-img:hover {
    border-bottom-left-radius: 50%;
    border-top-right-radius: 50%;
    border-top-left-radius: 10%;
    border-bottom-right-radius: 10%;
}

.featured-product-item > h3 {
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 10px;
}

.featured-product-item-link > p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 150px;
    font-size: 16px;
    letter-spacing: 2px;
}

.featured-product-item-link > p > span {
    font-size: 36px;
    margin-right: 10px;
}

@media (max-width: 450px) {
    .home-description > h3 {
        font-size: 24px;
    }
    
    .featured-products {
        flex-direction: column;
        height: auto;
    }

    .featured-product-item-img {
        width: 80vw;
    }
}
.loader-container {
    display: flex;
    background-color: var(--yellow);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.loader-text {
    font-size: 24px;
}
.store-images-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.store-imageOne {
    position: absolute;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    height: 60vh;
    width: 30%;
    top: 10%;
    left: 50%;
    order: 2;
    z-index: 2;
}

.store-imageTwo {
    position: absolute;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    height: 100vh;
    width: 40%;
    left: 15%;
    order: 1;
    z-index: 1;
}

.store-imageOne > img,
.store-imageTwo > img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
}

.store-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
    font-size: 50px;
    letter-spacing: 5px;
    text-transform: uppercase;
}

.store-products {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
}

@media (max-width: 450px) {
    .store-imageOne {
        width: 65%;
        left: 0;
        height: 50%;
    }

    .store-imageTwo {
        left: 20%;
        width: 80%;
    }

    .store-header {
       font-size: 24px;
    }

    .store-products {
        display: flex;
        flex-direction: column;
    }
}
/* Abstracts */

/* Base */

/* Components */

/* Pages */

