footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 10vh;
    margin-bottom: 10px;
}

.footer__social {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 5vh;
    width: 25%;
}

.footer__social > a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    color: var(--white);
    background-color: var(--black);
}

.footer__social > a:hover {
    background-color: var(--brown);
    color: var(--grey);
    transition: 0.5s ease-in-out;
}

.footer__credits {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 10px;
    margin-top: 20px;
    width: 40%;
}

.footer__credits > p {
    font-size: 10px;
}

.footer__credits > a {
    font-weight: normal;
}

.footer__credits > p:hover, a:hover {
    color: var(--grey);
    transition: 0.5s ease-in-out;
}

@media (max-width: 480px) {
    footer {
        margin-bottom: 5px;
    }

    .footer__social {
        width: 50%;
    }

    .footer__credits {
        width: 100%;
    }    
}
