.intro-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 100%;
    margin-top: 10vh;
}

.intro-container > h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 100px;
    font-weight: 200;
    letter-spacing: 5px;
    width: 75%;
}

@media (max-width: 450px) {
    .intro-container > h2 {
        font-size: 40px;
    }
}