.blog-post-main-img-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 75vh;
}

.blog-post-title {
    position: absolute;
    left: 50px;
    bottom: 25px;
    font-size: 30px;
    letter-spacing: 2px;
    color: var(--grey);
}

.blog-post-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin: 50px auto;
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 1.5em;
}

@media (max-width: 450px) {
    .blog-post-title {
        left: 25px;
    }
}

