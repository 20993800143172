.loader-container {
    display: flex;
    background-color: var(--yellow);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.loader-text {
    font-size: 24px;
}