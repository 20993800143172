.blog-items-container {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    align-items: center;
    width: 1none;
    height: auto;
}

.blog-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 25vw;
    height: 50vh;
    margin: 25px;
}

.blog-item-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25vw;
    height: 30vh;
    transition: 850ms;
    overflow: hidden;
}

.blog-item-img > img {
    object-fit: cover;
    object-position: center;
    transition: 850ms;
}

.blog-item:hover .blog-item-img {
    border-bottom-left-radius: none;
    border-top-right-radius: 50%;
    border-top-left-radius: 10%;
    border-bottom-right-radius: 10%;
}

.blog-item:hover .blog-item-img > img {
    transform: scale(1.1);
    transition: 0.5s ease-in-out;
}

.blog-item > h2 {
    font-size: 30px;
    margin: 10px;
}

.blog-item-description {
    letter-spacing: 2px;
    line-height: 1.5em;
}

@media (max-width: 450px) {
    .blog-items-container {
        display: flex;
        flex-direction: column;
    }

    .blog-item {
        width: 80vw;
        height: 50vh;
        margin: 25px;
    }

    .blog-item-img {
        width: 80vw;
    }

    .blog-item:hover .blog-item-img {
        border-bottom-left-radius: none;
        border-top-right-radius: none;
        border-top-left-radius: none;
        border-bottom-right-radius: none;
    }
}