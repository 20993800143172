.menuBtn {
    background-color: var(--black);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    margin-right: 50px;
    border-radius: 50%;
    z-index: 500;
}

.menuBtn .menuBtn__txt {
    position: absolute;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 12px;
    right: 100%;
    margin-right: 2px;
    z-index: 100;
    opacity: 0;
    transform: translateY(10%);
    transition: 0.3s 0s;
}

.menuBtn:hover > .menuBtn__txt {
    opacity: 1;
    transition: 0.5s ease-in-out;
}

@media (max-width: 450px) {
    .menuBtn {
        margin-right: 10px;
    }
}